import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import Home from "./subpages/Home";
import About from "./subpages/About";

function Router() {
  const theme =
    createTheme({
      palette: {
        mode: "dark"
      }
    });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/about'} element={<About />} />
          <Route path={'*'} element={<NotFound />} /> 
          {/* 最终的通配符放在最底下 */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ color: 'white' }}>你来到了没有知识的荒原</h1>
    </div>

  )
}

export default Router;