import { Box, Card, CardContent, Container, Typography } from "@mui/material";

function About() {
  return (
    <Container maxWidth={"lg"}>
      <Box className={"App"}>
        <Card sx={{ maxHeight: '90vh' }}>
          <CardContent>

            <Typography variant={"h3"}>
              About
            </Typography>

            <Typography color={"text.secondary"}>
              Hi, this is NekoRectifier.
            </Typography>

            <br />
            <Typography variant={"h5"}>
              Background
            </Typography>

            <Typography color={"text.secondary"}>
              Former Undergraduate who deeply indulges in Psychology theories and discoveries. 
            </Typography>

            <br />
            <Typography variant={"h5"}>
              Hobbies
            </Typography>

            <Typography color={"text.secondary"}>
              Sleeping...
              Reading...
              Dazing...
            </Typography>

          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}

export default About;